import React, { useState, useEffect, useMemo } from "react";
import Atom from "../../atoms";
import Molecule from "../../molecules";
import Organism from "../../organisms";
import { cn } from "lib/utils";
import { removeDuplicateVariables } from 'lib/storage';

const CampaignDetails = ({ campaign }) => {
    const [columns, setColumns] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const totalPages = Math.ceil(campaign.details?.length / itemsPerPage);

    const getStartingIndex = () => (currentPage - 1) * itemsPerPage;

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const uniqueVariables = useMemo(() => {
        if (!campaign || !campaign.variables) {
            return [];
        }
        return removeDuplicateVariables(campaign.variables);
    }, [campaign]);

    const DataCell = ({ requiredData, linkType }) => {
        return (
            <div className={cn(`flex justify-center items-center ${linkType === true ? 'text-indigo-600 underline text-sm' : null}`)}>
                {requiredData}
            </div>
        );
    };

    const getDataSource = (campaignDetails, variables) => {
        if (!campaignDetails) return null;

        const summaryDataCells = campaignDetails?.summaryData?.map((summary) => {
            const dynamicVariables = variables?.reduce((acc, { variable }) => {
                acc[variable] = <DataCell requiredData={summary?.[variable]} />; 
                return acc;
            }, {});
       
            return {
                key: summary.id,
                email: <DataCell requiredData={summary?.Email} />,
                ...dynamicVariables, 
                videoUrl: <DataCell requiredData={summary?.video_url} linkType={true} />,
                landingPageUrl: <DataCell requiredData={summary?.landing_page} linkType={true} />,
                thumbnailUrl: <DataCell requiredData={summary?.thumbnail} linkType={true} />,
                status: <DataCell requiredData={summary?.status} />,
            };
        });

        return summaryDataCells;
    };

    const dataSource = campaign ? getDataSource(campaign, uniqueVariables) : [];


    useEffect(() => {
        const variableColumns = [
            { title: "EMAIL", dataIndex: "email" },
            ...uniqueVariables.map(variable => ({
                title: variable.variable.toUpperCase().replace(/_/g, ' '),
                dataIndex: variable.variable,
                key: variable.variable
            }))
        ];

        const fixedColumnsEnd = [
            { title: "VIDEO URL", dataIndex: "videoUrl" },
            { title: "LANDING PAGE URL", dataIndex: "landingPageUrl" },
            { title: "THUMBNAIL URL", dataIndex: "thumbnailUrl" },
            { title: "STATUS", dataIndex: "status" },
        ];

        const updatedColumns = [...variableColumns, ...fixedColumnsEnd];

        setColumns(updatedColumns);
        setIsTableVisible(true);
    }, [campaign]);


    return (
        <Organism.Content className="px-5 gap-4 2xl:gap-6">
            <h1 className="text-2xl font-semibold">{campaign?.campaignName}</h1>
            <Atom.Visibility state={campaign?.length !== 0}>
                <Molecule.ContentTable className="px-0">
                    <Atom.Visibility state={isTableVisible}>
                        <Organism.Table dataSource={dataSource} columns={columns} type="campaignDetails" />
                    </Atom.Visibility>
                </Molecule.ContentTable>
                {/* <Molecule.ContentPagination>
                    <Organism.CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Molecule.ContentPagination> */}
            </Atom.Visibility>
        </Organism.Content>
    );
};

export default CampaignDetails;