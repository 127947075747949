import React, { useState, useEffect } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';
import Organism from 'common/components/organisms';
import { useParams, useNavigate } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner';
import { fetchData } from 'apis/APIWrapper';
import { NOT_FOUND_PAGE_PATH } from 'routes/routeConstants';


const CampaignDetailsPage = () => {

    const { campaignId } = useParams();
    const [campaignData, setCampaignData] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        fetchCampaignData();
    }, [campaignId]);

    const fetchCampaignData = async () => {
        try {
            setIsLoading(true);
            const response = await fetchData(`/admin/list/campaigns/${campaignId}`);
            if (response && response.campaignName) {
                setCampaignData(response);
            } else {
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            }
        } catch (error) {
            console.error("Error fetching campaign data:", error);
            navigate(NOT_FOUND_PAGE_PATH);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const storedCustomerId = localStorage.getItem('customerId');

    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.AdminPanel titleText="ReferScout">
                    <Organism.BreadcrumbTrails
                        breadcrumbs={[
                            { href: "/admin/customers", text: "Customers" },
                            { href: `/admin/customers/details/${storedCustomerId}`, text: "Campaigns" },
                            { text: campaignData?.campaignName }
                        ]}
                    />
                    {campaignData && Object.keys(campaignData).length > 0 && (
                        <AdminOrganism.CampaignDetails campaign={campaignData} />
                    )}
                </Template.AdminPanel>
            }
        </>
    )

}

export default CampaignDetailsPage;