import { useNavigate } from "react-router-dom";
import { Card, CardContent } from 'components/ui/card'
import { Progress } from 'components/ui/progress'
import React from 'react'
import Atom from '../atoms'
import { Zap } from 'lucide-react'
import { cn } from 'lib/utils'

const Credits = ({ showButton }) => {
    const navigate = useNavigate();

    const creditUsed = 4;
    const creditsAvailable = 10;

    return (
        <div className='px-2'>
            <Card className="bg-transparent border-0">
                <CardContent className="py-6">
                    <div className='text-center text-sm text-gray-600 mb-4 space-y-3'>
                        <p className='font-medium'>
                            <span className='font-bold'>{creditUsed}</span>(out of <span className='font-bold'>{creditsAvailable}</span>) Credits Used
                        </p>
                        <Progress
                            className={cn(`${!showButton ? 'w-80 mx-auto h-3' : 'h-2'}`,)}
                            value={(creditUsed / creditsAvailable) * 100}
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='text-gray-600 text-sm mb-2'>
                            Current plan : <span className='font-bold'>Plan name</span>
                        </p>
                        <Atom.Visibility state={showButton}>
                            <Atom.Button
                                type="sidebar"
                                className="hover:bg-violet-100 w-[90%]"
                                onClick={() => navigate('/plans')}
                            >
                                Upgrade
                                <Zap className='w-4 h-4 ml-2 fill-white' />
                            </Atom.Button>
                        </Atom.Visibility>

                    </div>
                </CardContent>
            </Card>
        </div >
    )
}

export default Credits;