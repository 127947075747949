import React, { useEffect, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
    AUTH_ROUTES,
    LOGIN_PATH,
    MAIN_ROUTES,
    LANDING_PAGE_ROUTES,
    ADMIN_PANEL_ROUTES,
    NOT_FOUND_PAGE_PATH
} from "./routeConstants";
import { SignedIn, SignedOut, useAuth, useClerk } from "@clerk/clerk-react";
import { setAuthHeaders, registerIntercepts } from "../apis/axios";
import Layout from "../Layout";
import AppContext from "common/context/AppContext";
import AdminLoginPage
    from "components/pages/adminPanelPages/AdminLoginPage";
import LoginPage from "components/pages/LoginPage";
import ProtectedRoute from "./ProtectedRoute";


const AppRouter = () => {
    const { sessionId } = useAuth();
    const { signOut } = useClerk();
    const { currentUser } = useContext(AppContext); 
    const location = useLocation();

    useEffect(() => {
        registerIntercepts(signOut);
        setAuthHeaders();
    }, [signOut]);

    return (
        <>
            <SignedOut>
                <Routes>
                    <Route path="/" element={<Navigate to={LOGIN_PATH} />} />
                    {AUTH_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<route.component />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {/* Redirect any attempt to access private routes to login when signed out */}
                    <Route
                        path="*"
                        element={<Navigate to={LOGIN_PATH} />}
                    />

                    {LANDING_PAGE_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<route.component />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                </Routes>
            </SignedOut>

            <SignedIn>
                <Routes>
                    <Route
                        path="/"
                        element={
                            currentUser.role === null
                                ? location.pathname === "/admin/login"
                                    ? <AdminLoginPage />
                                    : <LoginPage />
                                : currentUser.role === "Admin"
                                    ? <Navigate to="/admin/public-avatars" />
                                    : <Navigate to="/campaigns" />
                        }
                    />

                    {AUTH_ROUTES.map((route) => (
                        <Route
                            exact
                            element={
                                currentUser.role === null
                                    ? location.pathname === "/admin/login"
                                        ? <AdminLoginPage />
                                        : <LoginPage />
                                    : currentUser.role === "Admin"
                                        ? <Navigate to="/admin/public-avatars" />
                                        : <Navigate to="/campaigns" />
                            }
                            key={route.path}
                            path={route.path}
                        />
                    ))}

                    {MAIN_ROUTES.map((route) => (
                        <Route
                            exact
                            key={route.path}
                            path={route.path}
                            element={
                                <ProtectedRoute allowedRoles={["User"]}>
                                    <Layout>{<route.component />}</Layout>
                                </ProtectedRoute>
                            }
                        />
                    ))}

                    {ADMIN_PANEL_ROUTES.map((route) => (
                        <Route
                            exact
                            key={route.path}
                            path={route.path}
                            element={
                                <ProtectedRoute allowedRoles={["Admin"]}>
                                    <Layout>{<route.component />}</Layout>
                                </ProtectedRoute>
                            }
                        />
                    ))}

                    {LANDING_PAGE_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<route.component />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}

                    <Route
                        path={NOT_FOUND_PAGE_PATH}
                        element={<notFoundPage />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={NOT_FOUND_PAGE_PATH} />}
                    />
                </Routes>
            </SignedIn>

        </>
    );
};

export default AppRouter;
