import React, { useState, useContext } from "react";
import { ArrowUp, ArrowDown, X } from 'lucide-react';
import AppContext from '../../context/AppContext';
import { ScrollArea } from "components/ui/scroll-area";
import Atom from "common/components/atoms";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card";
import { CheckCircle2 } from "lucide-react";
import { cn } from "lib/utils";
import Organism from '../organisms';

const PricingHeader = ({ title, subtitle }) => (
    <section className="text-center">
        <h2 className="text-3xl font-bold">{title}</h2>
        <p className="text-xl pt-1">{subtitle}</p>
        <Organism.Credits showButton={false} />
        <br />
    </section>
);


const PricingCard = ({ isYearly, title, monthlyPrice, yearlyPrice, description, features, currentPlan, paymentLink, actionLabel }) => {

    let buttonText = actionLabel;
    let buttonIcon = null;

    if (currentPlan?.title === title) {
        buttonText = "Cancel Subscription";
        buttonIcon = <X size={16} className="mr-2" />;
    } else if (monthlyPrice > currentPlan?.monthlyPrice) {
        buttonText = "Upgrade";
        buttonIcon = <ArrowUp size={16} className="mr-2" />;
    } else if (monthlyPrice < currentPlan?.monthlyPrice) {
        buttonText = "Downgrade";
        buttonIcon = <ArrowDown size={16} className="mr-2" />;
    }


    return (
        <Card
            className={cn(`xl-w-72 3xl:w-[25rem] rounded-2xl shadow-md flex flex-col justify-between py-1 ${currentPlan?.title === title ? "border-violet-600 shadow-xl" : "border-zinc-700"} mx-auto sm:mx-0`, {
                "animate-background-shine bg-[linear-gradient(110deg,#e8e1fc,40%,#e0d7fc,55%,#d3cce8)] bg-[length:200%_100%] transition-colors": currentPlan?.title === title,
            })}
        >
            <div>
                <CardHeader className="pb-8 pt-4">
                    {isYearly && yearlyPrice && monthlyPrice ? (
                        <div className="flex justify-between">
                            <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg">{title}</CardTitle>
                            <div
                                className={cn("px-2.5 rounded-xl h-fit text-sm py-1 bg-zinc-200 text-black", {
                                    "bg-gradient-to-r from-purple-400 to-violet-400": currentPlan === title,
                                })}
                            >
                                Save ${monthlyPrice * 12 - yearlyPrice}
                            </div>
                        </div>
                    ) : (
                        <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg font-semibold">{title}</CardTitle>
                    )}
                    <Atom.Visibility state={title !== "Free"}>
                        <div className="flex gap-0.5">
                            <h3 className="text-3xl font-bold">{yearlyPrice && isYearly ? "$" + yearlyPrice : monthlyPrice ? "$" + monthlyPrice : "Custom"}</h3>
                            <span className="flex flex-col justify-end text-sm mb-1">{yearlyPrice && isYearly ? "/year" : monthlyPrice ? "/month" : null}</span>
                        </div>
                    </Atom.Visibility>
                    <CardDescription className="pt-1.5 h-12">{description}</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col gap-2">
                    {features.map((feature) => (
                        <CheckItem key={feature} text={feature} />
                    ))}
                </CardContent>
            </div>
            <Atom.Visibility state={title !== "Free"}>
                <CardFooter className="mt-2">
                    <Atom.Button
                        type="primary"
                        className={cn(`relative inline-flex w-[90%] mx-auto items-center justify-center rounded-3xl px-6 font-medium transition-all duration-300 focus:outline-none focus:scale-105 ${currentPlan?.title === title ? "bg-red-300 border-red-500 hover:bg-red-400" : null}`)}
                        onClick={() => window.open(paymentLink, "_blank")}
                    >
                        {buttonIcon}
                        {buttonText}
                    </Atom.Button>
                </CardFooter>
            </Atom.Visibility>
        </Card>
    );
};

const CheckItem = ({ text }) => (
    <div className="flex gap-2">
        <CheckCircle2 size={18} className="my-auto text-green-400" />
        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">{text}</p>
    </div>
);

const CheckoutForm = () => {
    const [isYearly, setIsYearly] = useState(false);
    const { currentPlan } = useContext(AppContext);

    const plans = [
        {
            title: "Free",
            monthlyPrice: 0,
            // yearlyPrice: 200,
            description: "Essential features you need to get started",
            features: ["3 videos", "1 campaign", "Public avatars only"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_fZe7tifi4dQBatifYZ",

        },
        {
            title: "Starter",
            monthlyPrice: 20,
            // yearlyPrice: 2200,
            description: "Perfect for owners of small & medium businesses",
            features: ["30 videos", "Unlimited Campaigns", "Custom Avatar creation", "Customizable thumbnail", "Customizable landing page", "CSV contact uploads"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_00g3d25HueUFdFu002",
        },
        {
            title: "Growth",
            monthlyPrice: 60,
            // yearlyPrice: 4000,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["100 videos", "Unlimited Campaigns", "Custom Avatar creation", "Customizable thumbnail", "Customizable landing page", "CSV contact uploads"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_eVa28Y6Ly13P0SIcMM",
        },
        {
            title: "Pro",
            monthlyPrice: 100,
            // yearlyPrice: 600,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["200 videos", "Unlimited Campaigns", "Custom Avatar creation", "Customizable thumbnail", "Customizable landing page", "CSV contact uploads"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_28oaFuc5SeUF30QcMQ",
        },
        {
            title: "Business",
            price: "Custom",
            monthlyPrice: 220,
            // yearlyPrice: 1000,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["500 videos", "Unlimited Campaigns", "Custom Avatar creation", "Customizable thumbnail", "Customizable landing page", "CSV contact uploads"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_14k5lac5SdQB44U147",
        },
        {
            title: "Scale",
            price: "Custom",
            monthlyPrice: 400,
            // yearlyPrice: 1000,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["1000 videos", "Unlimited Campaigns", "Custom Avatar creation", "Customizable thumbnail", "Customizable landing page", "CSV contact uploads"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_14k5lac5SdQB44U147",
        },
    ];

    return (
        <ScrollArea className="h-full w-full">
            <div className="py-8 2xl:py-16">
                <PricingHeader title="Pricing Plans" subtitle="Choose the plan that's right for you" />
                {/* <Organism.Credits /> */}
                <section className="xl:grid xl:grid-cols-3 mt-8 gap-8 xl:mx-14 3xl:mx-36">
                    {plans.map((plan) => (
                        <PricingCard key={plan.title} {...plan} isYearly={isYearly} currentPlan={currentPlan} />
                    ))}
                </section>
            </div>
        </ScrollArea>
    );
};

export default CheckoutForm;
