import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useSignIn } from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
// import { isClerkAPIResponseError } from '@clerk/clerk-react';
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useToast } from "components/ui/use-toast"
import { setAuthHeaders } from '../../apis/axios';
import { resetAuthTokens } from "apis/axios";
import { setToSessionStorage } from '../../lib/storage';
import { clearSessionStorageCredentials } from 'lib/storage';
import { fetchData } from "../../apis/APIWrapper";
import Template from '../../common/components/templates';
import AppContext from '../../common/context/AppContext';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import Atom from 'common/components/atoms';

const LoginPage = () => {
  const { setCurrentUser } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { signIn, setActive } = useSignIn();
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");
  const [isResetFlow, setIsResetFlow] = useState(false);
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [resetError, setResetError] = useState("");
  const { signOut } = useClerk();
  const { toast } = useToast()


  const LOGIN_FORM_INITIAL_VALUES = {
    email: "",
    password: "",
  };

  const LOGIN_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    code: Yup.string().required('Reset code is required'),
  });


  const handleLogout = async () => {
    resetAuthTokens();
    clearSessionStorageCredentials();
    await signOut();
  }


  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);

      const { email, password } = values;
      await handleLogout();
      const response = await signIn.create({
        identifier: email,
        password: password,
      });

      if (response.status !== "complete") {
        throw new Error("Username/Password incorrect"); // Generic error message
      }

      // Handle successful login...
      if (response.status === "complete") {
        await setActive({ session: response.createdSessionId });
        let session_id = response.createdSessionId;
        setToSessionStorage("authEmail", email);
        setToSessionStorage("authToken", session_id);
        setAuthHeaders();
        const apiResponse = await fetchData("/user/signin");
        if (apiResponse.status === 200) {
          setToSessionStorage("name", apiResponse.name);
          setToSessionStorage("company", apiResponse.company);
          setIsLoading(false);
          if (apiResponse.role === 1) {
            setCurrentUser({
              role: "User",
            });
            navigate("/campaigns");
          } else {
            // setFormError("You have entered an incorrect email or password. Please use the correct login credential and try again.");
            handleLogout();
            toast({
              duration: 3000,
              variant: "error",
              title: "You have entered an incorrect email or password. Please use the correct login credential and try again.",
            });
          }
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }

    } catch (error) {
      // setFormError("You have entered an incorrect email or password. Please use the correct login credential and try again.");
      toast({
        duration: 3000,
        variant: "error",
        title: "You have entered an incorrect email or password. Please use the correct login credential and try again.",
      });


      setIsLoading(false);
    } finally {
      console.log(formError);
      setSubmitting(false);
    }
  };


  const handleForgotPassword = async () => {
    setIsResetFlow(true); 
  };

  const sendResetCode = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);
      await signIn.create({
        strategy: 'reset_password_email_code',
        identifier: values.email,
      });
      setSuccessfulCreation(true);
      setResetError('');
    } catch (err) {
      // if (isClerkAPIResponseError(err)) {
      //   setResetError(err.errors[0]?.longMessage || "Failed to send reset code");
      // }
    } finally {
      setIsLoading(false)
      setSubmitting(false);
    }
  };


  const resetPassword = async (values, { setSubmitting }) => {
    try {
      setIsLoading(true);
      const result = await signIn.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code: values.code,
        password: values.password,
      });

      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });
        toast({
          duration: 3000,
          variant: "success",
          title: "Password reset successfully!",
        });
        navigate("/login");
      } else {
        toast({
          duration: 3000,
          variant: "error",
          title: "2FA required. Unsupported flow.",
        });
      }
    } catch (err) {
      // if (isClerkAPIResponseError(err)) {
      //   setResetError(err.errors[0]?.longMessage || "Password reset failed");
      // }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };


  const handleClosePasswordReset = () => {
    setIsResetFlow(false);
  }


  return (
    <>
      <Template.Login
        initialValues={LOGIN_FORM_INITIAL_VALUES}
        validationSchema={LOGIN_FORM_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        isPasswordVisible={isPasswordVisible}
        setIsPasswordVisible={setIsPasswordVisible}
        formError={formError}
        type="User"
        handleForgotPassword={handleForgotPassword}
      />

      <Dialog open={isResetFlow} DialogClose={handleClosePasswordReset} onOpenChange={setIsResetFlow}>
        <DialogContent className="py-10 px-16 flex flex-col gap-10 bg-[#F3F3F6]">
          <DialogHeader>
            <DialogTitle className="mx-auto text-xl font-bold">Reset Password</DialogTitle>
          </DialogHeader>
          <DialogDescription className="flex flex-col gap-4">

            {!successfulCreation ? (
              <Formik
                initialValues={{ email: '', password: '', code: '' }}
                validationSchema={forgotPasswordSchema}
                onSubmit={sendResetCode}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4 w-full max-w-sm">
                    <div className="flex flex-col space-y-1">
                      <label className="text-gray-700 font-medium" htmlFor="email">
                        Enter your email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="e.g., john@doe.com"
                        className="w-full h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                      />
                      <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                    </div>
                    <Atom.Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      width="fullwidth"
                    >
                      {isSubmitting ? 'Sending...' : 'Send Reset Code'}
                    </Atom.Button>
                    {resetError && <p className="text-red-500 text-sm">{resetError}</p>}
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{ password: '', code: '' }}
                validationSchema={resetPasswordSchema}
                onSubmit={resetPassword}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4 w-full max-w-sm">
                    <div>
                      <p className='italic text-xs text-center'>We have sent a reset code to your email, please enter the reset code and the new password below</p>
                    </div>

                    <div className="flex flex-col space-y-1">
                      <label className="text-gray-700 font-medium" htmlFor="code">
                        Enter Reset Code
                      </label>
                      <Field
                        name="code"
                        type="text"
                        placeholder="Enter your code"
                        className="w-full h-10 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#612FFF] transition"
                      />
                      <ErrorMessage name="code" component="div" className="text-red-500 text-xs" />
                    </div>

                    <div className="flex flex-col space-y-1">
                      <label className="text-gray-700 font-medium" htmlFor="password">
                        New Password
                      </label>
                      <Field
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        placeholder="******"
                        className="w-full h-10 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#612FFF] transition"
                      />
                      <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                    </div>

                    <Atom.Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      width="fullwidth"
                    >
                      {isSubmitting ? 'Resetting...' : 'Reset Password'}
                    </Atom.Button>
                    {resetError && <p className="text-red-500 text-sm">{resetError}</p>}
                  </Form>
                )}
              </Formik>
            )}
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginPage;