import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Atom from "../../atoms";
import Molecule from "../../molecules";
import Organism from "../../organisms";

import { isBreakpoint, createColumnsForTable } from "../../../../lib/index";
import { invitationStatus } from "../../../../lib/constants";
import { cn } from "../../../../lib/utils";
import CampaignsOrganism from "../campaigns";
import { fetchData } from "apis/APIWrapper";
import { ThreeCircles } from 'react-loader-spinner';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { Edit, MoreVertical, Trash } from "lucide-react";


const CampaignList = ({
	campaigns,
	handleOpenDialog,
	setCampaigns,
	allCampaigns
}) => {
	const navigate = useNavigate();
	const [columns, setColumns] = useState([]);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;
	const totalPages = Math.ceil(campaigns?.length / itemsPerPage);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [campaignToDelete, setCampaignToDelete] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const getStartingIndex = () => (currentPage - 1) * itemsPerPage;

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
		// Here we can also fetch new data based on the new page if the data is fetched from an API
	};

	const ImageCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				<img
					src={campaign.thumbnail?.thumbnail_url ? campaign.thumbnail?.thumbnail_url : '/draft.png'}
					alt="thumbnail"
					className="max-w-24 max-h-24 rounded-xl"
				/>
			</div>
		)
	}

	const CampaignNameCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				<div>{campaign.campaign_name}</div>
			</div>
		);
	};

	const CreatedOnCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				{formatDate(campaign.created_date)}
			</div>
		);
	};

	const ContactsAddedCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				{campaign.contacts_added || campaign.contacts_added === 0 ? campaign.contacts_added : 0}
			</div>
		);
	};

	const NoOfVideosCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				{campaign.no_of_videos || campaign.no_of_videos === 0 ? campaign.no_of_videos : 0}
			</div>
		);
	};

	const VideosViewedCell = ({ campaign }) => {
		return (
			<div className="flex justify-center items-center">
				{campaign.videos_viewed || campaign.videos_viewed === 0 ? campaign.videos_viewed : 0}
			</div>
		);
	};

	const StatusCell = ({ campaign, invitationStatus }) => {
		let secondaryColor;

		switch (invitationStatus[campaign.status].secondary_color) {
			case "green":
				secondaryColor = "bg-green-100 text-green-600";
				break;
			case "yellow":
				secondaryColor = "bg-yellow-100 text-yellow-600";
				break;
			case "black":
				secondaryColor = "bg-black text-white";
				break;
			default:
				secondaryColor = "bg-yellow-100 text-yellow-600";
				break;
		}

		return (
			<div className="flex justify-center items-center">
				<div
					className={cn(
						"rounded-xl capitalize font-medium text-sm py-1 px-3",
						secondaryColor
					)}
				>
					{invitationStatus[campaign.status].label}
				</div>
			</div>
		);
	};


	const ActionCell = ({ campaign }) => {

		const deleteButtonHandler = (campaign) => {
			setCampaignToDelete(campaign);
			setOpenDeleteDialog(true);
		}

		const openThumbnailEditHandler = (campaign) => {
			let thumbnailStep;
			if (campaign?.input_video?.video_mode === 'uploaded') {
				thumbnailStep = 6;
			} else {
				thumbnailStep = 7;
			}
			navigate(`/campaigns/create/thumbnail/${campaign.id}/${thumbnailStep}`, { state: { isEditing: true, returnPath: window.location.pathname } });
		}

		const openLandingPageEditHandler = (campaign) => {
			let landingPageStep;
			if (campaign?.input_video?.video_mode === 'uploaded') {
				landingPageStep = 7;
			} else {
				landingPageStep = 8;
			}
			navigate(`/campaigns/create/landing-page/${campaign.id}/${landingPageStep}`, { state: { isEditing: true, returnPath: window.location.pathname } });
		}

		return (
			<div className="group flex justify-center items-center p-2">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="secondary" className="rounded-full bg-transparent"> {/* h-[3.3rem] w-[3.3rem] */}
							<MoreVertical className="text-gray-400" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="min-w-[12rem]">
						{campaign.status !== "Draft" && (
							<DropdownMenuItem onClick={(e) => {
								e.stopPropagation();
								openThumbnailEditHandler(campaign);
							}}>
								<Edit className="w-4 h-4 mr-2" />
								Edit Thumbnail
							</DropdownMenuItem>
						)}

						{campaign.status !== "Draft" && (
							<DropdownMenuItem onClick={(e) => {
								e.stopPropagation();
								openLandingPageEditHandler(campaign);
							}}>
								<Edit className="w-4 h-4 mr-2" />
								Edit Landing Page
							</DropdownMenuItem>
						)}

						<DropdownMenuItem onClick={(e) => {
							e.stopPropagation();
							deleteButtonHandler(campaign);
						}}>
							<Trash className="w-4 h-4 mr-2" />
							Delete Campaign
						</DropdownMenuItem>

					</DropdownMenuContent>
				</DropdownMenu>

			</div>
		)
	}

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	}

	const handleDeleteCampaign = async (campaignId) => {
		setIsLoading(true);
		try {
			const response = await fetchData(`/campaigns/delete/${campaignId}`, 'DELETE');
			if (response) {
				setCampaigns((prevCampaigns) => prevCampaigns.filter(campaign => campaign.id !== campaignId));
				setOpenDeleteDialog(false);
				// window.location.reload();
			}
		} catch (error) {
			console.error('Failed to delete campaign:', error);
			setIsLoading(false);
			setOpenDeleteDialog(false);

		} finally {
			setIsLoading(false);
		}
	};

	const dataSource = campaigns
		?.slice(getStartingIndex(), getStartingIndex() + itemsPerPage)
		.map((campaign, index) => ({
			key: campaign.id,
			thumbnail: <ImageCell campaign={campaign} />,
			name: <CampaignNameCell campaign={campaign} />,
			createdOn: <CreatedOnCell campaign={campaign} />,
			contactsAdded: <ContactsAddedCell campaign={campaign} />,
			noOfVideos: <NoOfVideosCell campaign={campaign} />,
			videosViewed: <VideosViewedCell campaign={campaign} />,
			status: (
				<StatusCell campaign={campaign} invitationStatus={invitationStatus} />
			),
			action: <ActionCell campaign={campaign} />
		}));

	useEffect(() => {
		let adjustedColumn = createColumnsForTable(["NAME", "STATUS"]);

		if (isBreakpoint("md")) {
			adjustedColumn = createColumnsForTable(["NAME", "CREATED ON", "STATUS"]);
		}

		if (isBreakpoint("lg")) {
			adjustedColumn = createColumnsForTable([
				"CAMPAIGN",
				"NAME",
				"CREATED ON",
				"CONTACTS ADDED",
				"NO. OF VIDEOS",
				"VIDEOS VIEWED",
				"STATUS",
				"ACTION",
			]);
			adjustedColumn.push({
				title: "",
				dataIndex: "edit",
				key: "edit",
			});
		}
		setColumns(adjustedColumn);
		setIsTableVisible(true);
	}, []);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		const formattedDay = day < 10 ? "0" + day : day;
		const formattedMonth = month < 10 ? "0" + month : month;

		return `${formattedDay}-${formattedMonth}-${year}`;
	};


	if (!allCampaigns || allCampaigns.length === 0) {
		return <CampaignsOrganism.CampaignsDefault handleOpenDialog={handleOpenDialog} />;
	}

	return (
		<Organism.Content>
			<Atom.Visibility state={campaigns?.length > 0}>
				<Atom.Visibility state={isLoading}>
					<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
						<ThreeCircles
							visible={true}
							height="100"
							width="100"
							color="#e6a6e0"
							ariaLabel="three-circles-loading"
							wrapperStyle={{}}
							wrapperClass=""
						/>
					</div>
				</Atom.Visibility>
				<Molecule.ContentTable>
					<Atom.Visibility state={isTableVisible}>
						<Organism.Table dataSource={dataSource} columns={columns} campaigns={campaigns} type="campaignSummary" />
					</Atom.Visibility>
				</Molecule.ContentTable>
				<Molecule.ContentPagination>
					<Organism.CustomPagination
						currentPage={currentPage}
						totalPages={totalPages}
						onPageChange={handlePageChange}
					/>
				</Molecule.ContentPagination>
				<Organism.Modal
					open={openDeleteDialog}
					setOpen={setOpenDeleteDialog}
					handleClose={handleCloseDeleteDialog}
					showInput="false"
					title={`Are you sure you want to delete your campaign ${campaignToDelete?.['campaign_name']}?`}
					confirmLabel="Delete"
					onCancel={handleCloseDeleteDialog}
					onConfirm={() => handleDeleteCampaign(campaignToDelete?.id)}

				/>
			</Atom.Visibility>
			<Atom.Visibility state={campaigns?.length === 0}>
				<p className="text-center text-gray-500 mt-4">No such campaign found</p>
			</Atom.Visibility>
		</Organism.Content>
	);
};

export default CampaignList;
