import React from 'react';
import Organism from '../organisms';

const AvatarsTemplate = ({ children, handleOpenDialog, onSearchChange }) => {
    return (
        <div className='flex flex-col h-full gap-1'>
            <Organism.Header
                title="Avatars"
                buttonLabel="Create Avatar"
                onButtonClick={handleOpenDialog}
                inputPlaceholder="Search your avatar"
                elements={["SEARCH", "BUTTON",]}
                listPage={true}
                onSearchChange={onSearchChange}

            />
            <main className='flex flex-col h-full gap-1 overflow-hidden'>{children}</main>
        </div>
    );
};

export default AvatarsTemplate;
