import React, { useState, useEffect, useCallback } from 'react';
import Template from '../../common/components/templates';
import AvatarOrganism from '../../common/components/organisms/avatars';
import { fetchData } from 'apis/APIWrapper';
import { getFromSessionStorage } from 'lib/storage';
import { ThreeCircles } from 'react-loader-spinner';
import Atom from "common/components/atoms";

const AvatarsPage = () => {

  const [openDialog, setOpenDialog] = useState(false);
  const [myAvatars, setMyAvatars] = useState([]);
  const [publicAvatars, setPublicAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredMyAvatars, setFilteredMyAvatars] = useState([]);
  const [filteredPublicAvatars, setFilteredPublicAvatars] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {

      const filteredMy = searchValue
        ? myAvatars.filter((avatar) =>
          avatar.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        : myAvatars;


      const filteredPublic = searchValue
        ? publicAvatars.filter((avatar) =>
          avatar.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        : publicAvatars;

      setFilteredMyAvatars(filteredMy);
      setFilteredPublicAvatars(filteredPublic);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, myAvatars, publicAvatars]);


  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const Avatars = await fetchData("/avatar/private", 'POST', { created_by_company: getFromSessionStorage("company") });
      if (Avatars) {
        setIsLoading(false);
        const privateAvatars = Avatars.filter(avatar => avatar.type === 'Private');
        const publicAvatars = Avatars.filter(avatar => avatar.type === 'Public');
        setMyAvatars(privateAvatars);
        setPublicAvatars(publicAvatars);
        setFilteredMyAvatars(privateAvatars);
        setFilteredPublicAvatars(publicAvatars);
      }
    };

    loadData();
  }, []);


  return (
    <>
      <Atom.Visibility state={isLoading}>
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
          <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#e6a6e0"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </Atom.Visibility>
      <Atom.Visibility state={!isLoading}>
        <Template.Avatars handleOpenDialog={handleOpenDialog} onSearchChange={handleSearchChange}>
          <AvatarOrganism.AvatarsList
            publicAvatars={filteredPublicAvatars}
            myAvatars={filteredMyAvatars}
            setMyAvatars={setMyAvatars}
            handleOpenDialog={handleOpenDialog}
            handleCloseDialog={handleCloseDialog}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />

          <Atom.Visibility state={filteredMyAvatars?.length === 0 && filteredPublicAvatars?.length === 0}>
            <p className="text-center text-gray-500 mt-4">No such avatar found</p>
          </Atom.Visibility>
        </Template.Avatars>
      </Atom.Visibility>
    </>
  )
}

export default AvatarsPage