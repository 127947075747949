import React, { useState, useEffect, useCallback } from 'react';
import Template from '../../common/components/templates';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import { fetchData } from "../../apis/APIWrapper";
import { getFromSessionStorage } from 'lib/storage';
import { ThreeCircles } from 'react-loader-spinner';
import Atom from "common/components/atoms";


const CampaignsPage = () => {

   const [openDialog, setOpenDialog] = useState(false);
   const [campaigns, setCampaigns] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [filteredCampaigns, setFilteredCampaigns] = useState([]);
   const [searchValue, setSearchValue] = useState('');

   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = () => {
      setOpenDialog(false);
   };

   const handleSearchChange = useCallback((e) => {
      setSearchValue(e.target.value);
   }, []);


   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         if (searchValue) {
            const filtered = campaigns.filter((campaign) =>
               campaign.campaign_name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredCampaigns(filtered);
         } else {
            setFilteredCampaigns(campaigns);
         }
      }, 300);
      return () => clearTimeout(delayDebounceFn);
   }, [searchValue, campaigns]);


   useEffect(() => {
      if (!window.sessionStorage.length) {
         localStorage.clear();
         window.location.reload();
      }
   }, []);

   useEffect(() => {
      const fetchCampaignData = async () => {
         setIsLoading(true);
         try {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await fetchData('/campaigns', 'POST', {
               created_by_company: getFromSessionStorage("company"),
            });

            if (response) {
               setCampaigns(response);
            }
         } catch (error) {
            console.error('Error fetching campaigns:', error);
         } finally {
            setIsLoading(false);
         }
      };
      fetchCampaignData();
   }, []);


   return (
      <>
         <Atom.Visibility state={isLoading}>
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
               <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#e6a6e0"
                  ariaLabel="three-circles-loading"
               />
            </div>
         </Atom.Visibility>

         {campaigns && (
            <Template.Campaigns
               handleOpenDialog={handleOpenDialog}
               onSearchChange={handleSearchChange}
            >
               <CampaignsOrganism.CampaignsList
                  campaigns={filteredCampaigns}
                  allCampaigns={campaigns}
                  setCampaigns={setCampaigns}
                  handleOpenDialog={handleOpenDialog}
                  handleCloseDialog={handleCloseDialog}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
               />
            </Template.Campaigns>
         )}

         <CampaignsOrganism.CreateNewCampaign
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleCloseDialog={handleCloseDialog}
         />
      </>
   );

};

export default CampaignsPage;
