import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppContext from "common/context/AppContext";
import { useClerk } from "@clerk/clerk-react";

const ProtectedRoute = ({ allowedRoles, children }) => {
  const { currentUser } = useContext(AppContext);
  const { signOut } = useClerk();
  const location = useLocation();

  useEffect(() => {
    if (!allowedRoles.includes(currentUser.role)) {
      const handleUnauthorizedAccess = async () => {
        await signOut();
        sessionStorage.clear();
      };
      handleUnauthorizedAccess();
    }
  }, [currentUser.role, allowedRoles, signOut]);

  if (!currentUser.role || !allowedRoles.includes(currentUser.role)) {
    return <Navigate to="/login" state={{ from: location }} replace />;

  }

  return children;
};

export default ProtectedRoute;