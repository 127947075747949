import React, { useState, useEffect, useMemo, useCallback } from 'react';
import useSWR from 'swr'
import { useParams } from "react-router-dom";
import Papa from 'papaparse';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../../../../components/ui/dialog";
import { CircleDollarSign, Clock, Eye, MousePointerClick, Play, Users, Plus, Wand } from 'lucide-react';
import Atom from 'common/components/atoms';
import Organism from 'common/components/organisms';
import CampaignsOrganism from '.';
import { ScrollArea } from 'components/ui/scroll-area';
import { fetchData } from 'apis/APIWrapper';
import { useToast } from "components/ui/use-toast"
import { removeDuplicateVariables } from 'lib/storage';


const StatusNames = ["Pending", "Queued", "Started", "Completed"];

const CampaignSummary = ({ campaignData, setIsLoading }) => {
    const { toast } = useToast()
    // const [campaignData, setCampaignData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [generateModalIsOpen, setGenerateModalIsOpen] = useState(false);
    const [generatedData, setGeneratedData] = useState([]);
    const { campaignId } = useParams();
    // const [isSubmitting, setIsSubmitting] = useState(false);


    const keyStatsData = [
        {
            title: 'Contacts Added',
            value: campaignData['contacts_added'],
            icon: <Users />,
        },
        {
            title: 'Videos Generated',
            value: campaignData['videos_generated'],
            icon: <Play />,
        },
        {
            title: 'Links Clicked',
            value: campaignData['links_clicked'],
            icon: <MousePointerClick />,
        },
        {
            title: 'Total Views',
            value: campaignData['videos_viewed'],
            icon: <Eye />,
        },
        {
            title: 'Watch Time',
            value: campaignData['watch_time'],
            icon: <Clock />,
        },
        {
            title: 'CTA Clicks',
            value: campaignData['cta_clicks'],
            icon: <CircleDollarSign />,
        }
    ]

    const uniqueVariables = useMemo(() => {
        if (!campaignData || !campaignData.variables) {
            return [];
        }
        return removeDuplicateVariables(campaignData.variables);
    }, [campaignData]);


    const variablesWithEmail = useMemo(() => {
        if (uniqueVariables.length === 0) {
            return ["Email"];
        }

        return [
            "Email", ...uniqueVariables.map(v => {
                if (typeof v.variable === 'string' && v.variable.includes(' ')) {
                    return v.variable.replace(/\s+/g, '_');
                } else {
                    return v.variable;
                }
            })
        ];
    }, [campaignData]);

    const fetchCampaignData = useCallback(async () => {
        const response = await fetchData(`/summary/${campaignId}`)
        if (response) {
            setTableData(response);
        }
    }, [campaignId]);

    const { data, error } = useSWR(`/summary/${campaignId}`, fetchData, {
        refreshInterval: 10000,
        onSuccess: (fetchedData) => {
            setGeneratedData(fetchedData);
        }
    });

    useEffect(() => {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    useEffect(() => {
        fetchCampaignData()
    }, [fetchCampaignData]);


    const addDataHandler = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedFile(null);
        setGenerateModalIsOpen(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setErrorMessage('');
        }
    };

    const convertVariablesToCsv = () => {
        if (!variablesWithEmail || variablesWithEmail.length === 0) {
            return '';
        }
        const variablesCsvContent = [variablesWithEmail].map(row => [row]).map(row => row.join(',')).join('\n');
        return 'data:text/csv;charset=utf-8,' + encodeURIComponent(variablesCsvContent);
    }

    const downloadCsvSampleHandler = () => {
        const csvVariables = convertVariablesToCsv();
        if (csvVariables) {
            const link = document.createElement('a');
            link.setAttribute('href', csvVariables);
            link.setAttribute('download', 'variablesData.csv');
            link.click();
        }
    }

    const parseCSV = (csvText) => {
        Papa.parse(csvText, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
                const existingEmails = csvData.map(row => row.email);
                const newEmails = results.data.map(row => row.email);

                const commonEmails = newEmails.filter(email => existingEmails.includes(email));

                if (commonEmails.length > 0) {
                    console.log('There are common emails:', commonEmails);
                } else {
                    console.log('There are no common emails');
                }
                // setCsvData((prevData) => [...prevData, ...results.data]);
                setCsvData(results.data)
            }
        });
    }

    const handleFileUpload = () => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvText = e.target.result;
                parseCSV(csvText);
            };
            reader.readAsText(selectedFile);
            handleCloseDialog();
        } else {
            setErrorMessage('Please select a file');
        }
    };

    // const newData = tableData.filter(data => !data?._id);
    const newData = tableData.filter(data => data?.status === 'Pending');


    const generateButtonHandler = () => {
        const isAnyRequiredFieldEmpty = newData.some(item => {
            return Object.keys(item).some(key => {
                return key !== "videoUrl" && key !== "thumbnail" && key !== "landing_page" && key !== "status" && key !== "delete" && !item[key];
            });
        });

        if (isAnyRequiredFieldEmpty) {
            toast({
                duration: "4000",
                variant: "warning",
                title: "Incomplete data!",
                description: "Please ensure all required fields are filled before you generate.",
            });
            return;
        }

        if (newData.length === 0) {
            toast({
                duration: "4000",
                variant: "warning",
                title: "No new data added!",
                description: "Please add data to the table before you generate.",
            });
            return;
        }

        setGenerateModalIsOpen(true);
    };

    const dataGenerateHandler = async () => {
        setIsLoading(true);
        setGenerateModalIsOpen(false);
        try {
            const response = await fetchData(`/summary/${campaignId}`, 'POST', newData);
            if (response) {
                const allGeneratedData = tableData.filter(data => data?._id);
                // setGeneratedData(prevGeneratedData => [...prevGeneratedData, response]);
                setGeneratedData([...allGeneratedData, response]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // setIsSubmitting(true);
            setIsLoading(false);
        }
    };

    function replaceTranscriptWithStyledVariables(transcript, variables) {
        let words = transcript.split(' ');

        variables.forEach(({ variable, index }) => {
            words[index] = <span className='font-semibold text-violet-600'>@{variable}</span>;
        });

        return words;
    }

    const TranscriptDisplay = ({ transcript, variables }) => {
        const modifiedTranscript = replaceTranscriptWithStyledVariables(transcript, variables);

        return <p>{modifiedTranscript.map((word, i) => <span key={i}> {word} </span>)}</p>;
    };

    return (
        <ScrollArea>
            <div className='mx-6 grid gap-8 py-4'>
                <div className='grid grid-cols-7 gap-10'>
                    <div className='col-span-7 md:col-span-3 my-auto flex justify-center items-center'>
                        <div className="relative rounded-3xl overflow-hidden w-full after:content-[''] after:block after:pt-[56.25%]">
                            <video                            
                                controls
                                className="absolute top-0 left-0 w-full h-full"
                                src={campaignData?.input_video?.s3_file}
                            />
                        </div>
                    </div>
                    <div className='col-span-7 md:col-span-4'>
                        <Tabs defaultValue='script' className='flex flex-col gap-4'>
                            <TabsList className="bg-transparent grid grid-cols-2 gap-3 w-full">
                                <TabsTrigger value="script" className="col-span-1 border">Script</TabsTrigger>
                                <TabsTrigger value="variable" className="col-span-1 border">Variables</TabsTrigger>
                            </TabsList>
                            <div>
                                <TabsContent value="script">
                                    <div className=" border border-[#D5D5D5] rounded-3xl p-4 h-[215px] text-sm">
                                        <TranscriptDisplay transcript={campaignData.transcription} variables={campaignData.variables} />
                                    </div>
                                </TabsContent>
                                <TabsContent value="variable">
                                    <div className="flex gap-2 items-start border border-[#D5D5D5] rounded-3xl p-4 h-[215px] text-sm">
                                        {uniqueVariables?.map((v, index) => (
                                            <li
                                                key={index}
                                                className="list-none border border-violet-600 text-violet-600 bg-violet-100 rounded-3xl px-3 py-2 text-xs font-semibold"
                                            >
                                                {v.variable}</li>
                                        ))}
                                    </div>
                                </TabsContent>
                            </div>
                        </Tabs>
                    </div>
                </div>

                <div className='space-y-4'>
                    <h2 className='font-bold text-[#444444]'>Campaign Stats</h2>
                    <div className='flex flex-wrap lg:flex-nowrap gap-8 rounded-3xl p-6 bg-[#EFEAFF] items-center'>
                        {/* <div className='grid gap-4 rounded-3xl p-4 h-[10vh] bg-[#EFEAFF]'> */}
                        {keyStatsData.map((item) => (
                            <div
                                key={item.title}
                                className='flex grow gap-2 max-w-[20%] text-sm'
                            // style={{ maxWidth: '25%', flexBasis: '20%' }}
                            >
                                {item.icon}
                                <p><span className='mr-1 font-semibold'>{item.value}</span>{item.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='space-y-4'>
                    {/* <div className='flex flex-col gap-2'> */}
                    <div className='flex justify-between'>
                        <div className='flex flex-col gap-2'>
                            <h2 className='font-bold text-[#444444]'>Videos Made Using This Template</h2>
                            <p className='text-xs italic text-gray-500'>Please fill in the highlighted fields and click on genenrate</p>
                        </div>
                        <div className='flex gap-2'>
                            <Atom.Button
                                type="outline"
                                onClick={addDataHandler}
                            >
                                <Plus className="h-4 w-4 text-[#612FFF] mr-2" />
                                Add Data
                            </Atom.Button>
                            <Atom.Button type="primary" onClick={generateButtonHandler}>
                                <Wand className="h-4 w-4 mr-2" />
                                Generate Video
                            </Atom.Button>

                        </div>

                        <Dialog open={openDialog} DialogClose={handleCloseDialog} onOpenChange={setOpenDialog}>
                            <DialogContent className="py-10 px-16 flex flex-col gap-10 bg-[#F3F3F6]">
                                <DialogHeader>
                                    <DialogTitle className="mx-auto text-xl font-bold">Upload your CSV</DialogTitle>
                                </DialogHeader>
                                <DialogDescription className="flex flex-col gap-4">
                                    <span style={{ color: '#333', fontWeight: '400', marginBottom: '0.6rem' }}>Here is a format for sample CSV</span>
                                    <Atom.Button type="skull" style={{ borderRadius: '8px', padding: '0.2rem 0.8rem', fontSize: '12px' }} onClick={downloadCsvSampleHandler}>Download Sample</Atom.Button>
                                    <span style={{ color: '#333', fontWeight: '500', margin: '3rem 0 1.3rem 0' }}>Upload your CSV file here</span>
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileChange}
                                    />
                                    {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
                                </DialogDescription>
                                <DialogFooter className="md:flex flex-2">
                                    <Atom.Button type="secondary" width="fullwidth" onClick={handleCloseDialog}>
                                        Cancel
                                    </Atom.Button>
                                    <Atom.Button type="primary" width="fullwidth" onClick={handleFileUpload}>
                                        Upload
                                    </Atom.Button>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>
                    {/* {csvData.length !== 0 ? */}
                    <div
                        className='flex flex-col gap-4 justify-center'
                    >
                        <CampaignsOrganism.SummaryTable
                            csvData={csvData}
                            tableData={tableData}
                            setTableData={setTableData}
                            statuses={StatusNames}
                            generatedData={generatedData}
                            variables={uniqueVariables}
                        />
                    </div>
                    {/* : null} */}
                    <Organism.Modal
                        open={generateModalIsOpen}
                        setOpen={setGenerateModalIsOpen}
                        handleClose={handleCloseDialog}
                        title="Please make sure all the contact details are correct"
                        showSubHeading='true'
                        subHeading="Once you generate the videos, you won't be able to edit the existing contact details. However, you will be able to add new contacts to the campaign anytime later."
                        confirmLabel="Yes, Generate"
                        onCancel={handleCloseDialog}
                        onConfirm={dataGenerateHandler}
                    />
                </div>
            </div>
            {/* } */}
        </ScrollArea>
    )
}

export default CampaignSummary;