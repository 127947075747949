import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignUp, useClerk } from "@clerk/clerk-react";
import * as Yup from "yup";
import Template from "../../common/components/templates";
import { fetchData } from "../../apis/APIWrapper";
import { useToast } from "components/ui/use-toast"
import Atom from "common/components/atoms";
import { Input } from "components/ui/input";

const SignUpPage = () => {
	const { signUp, setActive } = useSignUp();
	const navigate = useNavigate();
	const { toast } = useToast()
	const { signOut } = useClerk();

	const [isChecked, setIsChecked] = useState(false);
	const [formError, setFormError] = useState("");
	const [verificationStatus, setVerificationStatus] = useState("idle");
	const [verificationCode, setVerificationCode] = useState("");
	const [formValues, setFormValues] = useState(null);

	const SIGNUP_FORM_INITIAL_VALUES = {
		name: "",
		email: "",
		companyName: "",
		password: "",
		confirmPassword: "",
	};

	const SIGNUP_FORM_VALIDATION_SCHEMA = Yup.object({
		name: Yup.string().required("Enter your name"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Enter your email"),
		companyName: Yup.string().required("Enter your company name"),
		password: Yup.string()
			.min(8, "Password is too short - should be 8 chars minimum")
			.required("Enter your password"),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref("password"), null],
			"Passwords must match"
		),
	});

	async function hashPassword(password) {
		const encoder = new TextEncoder();
		const data = encoder.encode(password);
		const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const hashedPassword = await hashPassword(values.password);

			setFormValues({
				name: values.name,
				company_name: values.companyName,
				email: values.email,
				password: hashedPassword,
				role: 1,
			});

			// Clerk signup request
			const signUpResponse = await signUp.create({
				emailAddress: values.email,
				password: values.password,
			});

			if (signUpResponse.status === "missing_requirements") {
				// Prepare the email verification
				const prepared = await signUp.prepareEmailAddressVerification({
					strategy: "email_code",
				});

				if (prepared) {
					setVerificationStatus("pending");
				} else {
					setFormError("Failed to initiate email verification. Please try again.");
				}
			} else {
				setFormError("Unexpected error. Please try again later.");
			}

		} catch (error) {
			console.error("Error during Clerk signup:", error);
			if (error.status === 422) {
				setFormError(error.errors[0].message);
			} else {
				setFormError("An unexpected error occurred. Please try again.");
			}
		} finally {
			setSubmitting(false);
		}
	};


	const handleVerification = async () => {
		try {
			const completeSignUp = await signUp.attemptEmailAddressVerification({
				code: verificationCode,
			});

			if (completeSignUp.status === "complete") {
				await setActive({ session: completeSignUp.createdSessionId });
				setVerificationStatus("complete");

				if (!formValues) {
					setFormError("An error occurred. Please try again.");
					return;
				}

				const payload = {
					...formValues,
				};

				const apiResponse = await fetchData("/user/signup", "POST", payload);

				if (apiResponse.status === 200) {
					toast({
						duration: 3000,
						variant: "success",
						title: "Account created successfully.",
					});
					await signOut();
					navigate("/login");
				} else {
					setFormError("Failed to create account. Please try again.");
				}

			} else {
				setFormError("Invalid verification code. Please try again.");
			}
		} catch (error) {
			setFormError("Failed to verify email. Please try again.");
		}
	};

	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};

	if (verificationStatus === "pending") {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
				<div className="flex flex-col items-center p-10 bg-white rounded-xl shadow-md">
					<h2 className="mb-4 text-2xl font-bold">Verify Your Email</h2>
					<p className="mb-4 italic">Please verify your email to activate your account. Enter the verification code sent to your email below.</p>
					<Input
						type="text"
						className="w-full p-2 mb-4"
						placeholder="Enter verification code"
						value={verificationCode}
						onChange={(e) => setVerificationCode(e.target.value)}
					/>
					<Atom.Button
						type="primary"
						className="px-4 py-2"
						onClick={handleVerification}
					>
						Verify Email
					</Atom.Button>
				</div>
			</div>
		);
	}

	return (
		<Template.SignUp
			initialValues={SIGNUP_FORM_INITIAL_VALUES}
			validationSchema={SIGNUP_FORM_VALIDATION_SCHEMA}
			isChecked={isChecked}
			onCheckboxChange={handleCheckboxChange}
			onSubmit={handleSubmit}
			formError={formError}
		/>
	);
}

export default SignUpPage;