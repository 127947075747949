import React from 'react';
import CampaignsIcon from '../../../assets/sidebar-icons/CampaignsIcon';
import AvatarIcon from '../../../assets/sidebar-icons/AvatarIcon';
import LibraryIcon from '../../../assets/sidebar-icons/LibraryIcon';
import { Receipt } from 'lucide-react'


export const routes = [
    { name: "Campaigns", to: "/campaigns", icon: state => (<CampaignsIcon isIconActive={state} />), current: true },
    { name: "Avatars", to: "/avatars", icon: state => (<AvatarIcon isIconActive={state} />), current: false },
    { name: "Billing", to: "/plans", icon: state => (<Receipt isIconActive={state} />), current: false },
]

export const AdminRoutes = [
    { name: "Public Avatars", to: "/admin/public-avatars", icon: state => (<CampaignsIcon isIconActive={state} />), current: true },
    { name: "Customers", to: "/admin/customers", icon: state => (<AvatarIcon isIconActive={state} />), current: false },
    { name: "Integrations", to: "/admin/integrations", icon: state => (<LibraryIcon isIconActive={state} />), current: false },
]