import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { ScrollArea } from '../ui/scroll-area';
import Atom from '../../common/components/atoms';
import { fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';
import { MainLogo } from 'common/assets/images';
import { Play } from 'lucide-react'

const LandingPage = () => {
  const { landingPageId } = useParams();
  const iframeRef = useRef(null);
  const [landingPageData, setLandingPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  useEffect(() => {
    if (landingPageId) {
      fetchLandingPageData();
    }
  }, [landingPageId]);

  const fetchLandingPageData = async () => {
    if (!landingPageId) return;

    setIsLoading(true);
    try {
      const response = await fetchData(`/landingPage/${landingPageId}`);
      if (response) {
        setLandingPageData(response);
      }
    } catch (error) {
      console.error('Error fetching landing page data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    let buttonLink = landingPageData?.button_link;

    if (buttonLink) {
      if (!buttonLink.startsWith("http")) {
        buttonLink = `https://${buttonLink}`;
      }

      window.open(buttonLink, "_blank");
    }
  };


  useEffect(() => {
    const injectScript = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow && landingPageData?.script) {
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
          <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Embedded Script</title>
            </head>
            <body>
              ${landingPageData?.script}
            </body>
          </html>
        `);
        doc.close();
      }
    };

    if (landingPageData?.script && iframeRef.current) {
      injectScript();
    }
  }, [landingPageData]);


  const handlePlayClick = () => {
    setIsPlaying(true)
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
          <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#e6a6e0"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

      ) :
        <ScrollArea className="h-[100%] w-full"> {/* set h-[100%] pb-6 for no outer scroll and scroll in custo block */}  {/* h-[99%] & 2xl:h-[94%] */}
          <div className={`h-[100%] min-h-[600px] md:min-h-[870px] 2xl:min-h-[900px] flex flex-grow flex-col gap-4 px-12 lg:px-14 py-10  ${landingPageData?.theme === 'theme 1' ? 'items-start' : 'items-center'}`} style={{ backgroundColor: landingPageData?.background_color }}>  {/* remove h- style for no outer scroll and scroll in custo block */}
            <img
              src={landingPageData?.logo_image_url || MainLogo}
              alt="Logo"
              style={{ width: `${landingPageData?.logo_width}%` }}
              className="mb-4"
            />
            {/* <div className={`flex ${landingPageData.theme === 'theme 1' ? 'flex-row' : 'flex-col'} items-center justify-center gap-4 min-w-[100%]`}> */}
            <div className={`flex flex-col sm:flex-col ${landingPageData?.theme === 'theme 1' ? 'lg:flex-row' : 'lg:flex-col'} items-center justify-center gap-4 min-w-[100%]`}>
              <div className="flex flex-col items-center text-wrap">
                <div className={`flex flex-col gap-1 items-center max-w-[90%] 2xl:max-w-[90%] min-w-[90%] 2xl:min-w-[90%] text-wrap`}>
                  <h1 className="text-2xl mb-2 font-semibold break-words" style={{ color: landingPageData?.font_color }}>
                    {landingPageData?.title}
                  </h1>
                  <p className="text-lg mb-4 text-center break-words" style={{ color: landingPageData?.font_color }}>
                    {landingPageData?.description}
                  </p>
                </div>

                {landingPageData?.button_enabled && (
                  <Atom.Button
                    type="primary"
                    style={{ backgroundColor: landingPageData?.button_color, border: "none", }}
                    className="min-w-[35%] lg:min-w-[12%]"
                    onClick={handleButtonClick}
                  >
                    {landingPageData?.button_text}
                  </Atom.Button>
                )}
              </div>
              <Atom.Visibility state={!landingPageData.thumbnail?.thumbnail_url}>
                <div className="w-full h-64 bg-gray-200 animate-pulse rounded-3xl"></div>
              </Atom.Visibility>
              <div className="relative mb-4 rounded-3xl w-full max-w-[710px] 2xl:max-w-[760px] min-w-[270px] 2xl:min-w-[470px] max-h-[370px] 2xl:max-h-[396px] min-h-[100px] 2xl:min-h-[260px]">
                {!isPlaying && (
                  <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-3xl overflow-hidden">
                    <img
                      src={landingPageData?.thumbnail.thumbnail_url}
                      alt="Video thumbnail"
                      className="absolute inset-0 w-full h-full object-cover"
                    />

                    {landingPageData?.thumbnail.text_position && (
                      <div
                        className={`absolute left-0 right-0 bg-[#18084B] text-[#DAFC09] px-3 py-2 md:text-sm 2xl:text-lg text-center ${landingPageData?.thumbnail.text_position === "bottom" ? 'bottom-0 rounded-b-3xl' :
                          landingPageData?.thumbnail.text_position === "top" ? 'top-0 rounded-t-3xl' : ''
                          }`}
                      >
                        {landingPageData?.thumbnail.text}
                      </div>
                    )}

                    <button
                      onClick={handlePlayClick}
                      className="relative z-10 w-16 h-16 bg-violet-100 opacity-50 rounded-full flex items-center justify-center hover:scale-110 transition-all duration-300"
                      aria-label="Play video"
                    >
                      <Play className="w-8 h-8 text-violet-600 fill-violet-600" />
                    </button>
                  </div>
                )}
                <video
                  ref={videoRef}
                  controls={isPlaying}
                  src={landingPageData?.videoUrl}
                  className="rounded-3xl w-full max-w-[710px] 2xl:max-w-[760px] min-w-[270px] 2xl:min-w-[470px] max-h-[370px] 2xl:max-h-[396px] min-h-[100px] 2xl:min-h-[260px] object-cover"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            {landingPageData?.script && (
              <iframe
                ref={iframeRef}
                className="w-[100%] h-[750px]"
                title="Embedded Script"
              />
            )}
          </div>
        </ScrollArea>
      }
    </>
  )
}

export default LandingPage;