import React from "react";
import { useNavigate } from "react-router-dom";
import Molecule from "../molecules";
import Atom from "../atoms";


const Table = ({ dataSource, columns, type, campaigns }) => {
  const navigate = useNavigate();

  const campaignStepRoutes = (campaign) => ({
    1: 'create',
    2: 'create/avatar',
    3: 'create/script',
    4: 'create/instructions',
    5: campaign?.input_video?.video_mode === 'uploaded' ? 'create/variables' : 'create/video',
    6: campaign?.input_video?.video_mode === 'uploaded' ? 'create/thumbnail' : 'create/variables',
    7: campaign?.input_video?.video_mode === 'uploaded' ? 'create/landing-page' : 'create/thumbnail',
    8: 'create/landing-page',
  });

  const handleRowClick = (id) => {
    if (type === "customers") {
      navigate(`/admin/customers/details/${id}`);
    } else if (type === "customerDetails") {
      navigate(`/admin/customers/campaign/${id}`);
    } else if (type === "campaignSummary") {
      const campaign = campaigns.find((campaign) => campaign.id === id);

      if (campaign) {
        if (campaign.status !== "Draft") {
          navigate(`/campaigns/summary/${id}`);
        } else {
          const stepRoutes = campaignStepRoutes(campaign);
          const stepRoute = stepRoutes[campaign.step] || `create/${campaign.step}`;
          navigate(`/campaigns/${stepRoute}/${id}/${campaign.step}`);
        }
      } else {
        console.error('Campaign not found:', id);
      }
    }
  }

  return (
    <table className="max-h-full min-w-full bg-white">
      <Molecule.TableHead className="">
        {columns.map((column) => (
          <Atom.TableCell
            content={column.title}
            key={column.key}
            className={`py-3 ${type !== "campaignSummary" ? "bg-violet-800 text-white" : ""} px-1 text-center text-xs 2xl:text-base`}
          />
        ))}
      </Molecule.TableHead>
      <Molecule.TableBody className="divide-y divide-[#eaecf0]">
        {dataSource.map((data) => (
          <Molecule.TableRow key={data.key} onClick={() => handleRowClick(data.key)}>
            {columns.map((column, index) => (
              <Atom.TableCell
                content={data[column.dataIndex]}
                key={index}
                className={`py-3 text-center text-sm 2xl:text-base ${type !== "campaignSummary" ? "max-w-[300px] truncate overflow-hidden" : ""}`}
              />
            ))}
          </Molecule.TableRow>
        ))}
      </Molecule.TableBody>
    </table>
  );
};


export default Table;
