import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
   const [variables, setVariables] = useState([{
      index: "1",
      value: "first_name",
   },
   {
      index: "2",
      value: "company_name",
   },
   ]);
   const [selectedVariables, setSelectedVariables] = useState([]);
   const [variablesWithoutDuplicates, setVariablesWithoutDuplicates] = useState([]);
   const [videoUrl, setVideoUrl] = useState("");
   const [landingPageUrl, setLandingPageUrl] = useState("landingpageurl.com");
   const [transcription, setTranscription] = useState("");
   const [thumbnailData, setThumbnailData] = useState({
      textPosition: "bottom",
      text: "",
   });
   const [landingPageData, setLandingPageData] = useState({
      titleText: "",
      descriptionText: "",
   });
   const [avatarId, setAvatarId] = useState()
   const [script, setScript] = useState()

   const [currentUser, setCurrentUser] = useState({
      role: null,  
   });

   const [avatarTrainingStep, setAvatarTrainingStep] = useState(0);

   const [currentPlan, setCurrentPlan] = useState({
      title: "Free",
      monthlyPrice: 0,
      // yearlyPrice: 200,
      description: "Essential features you need to get started",
      features: ["3 videos", "1 campaign", "Public avatars only"],
      actionLabel: "Buy",
      paymentLink: "https://buy.stripe.com/test_fZe7tifi4dQBatifYZ",
   },);

   const location = useLocation();
   const [isAuthenticated, setIsAuthenticated] = useState(false);

   useEffect(() => {
      const handleBeforeUnload = (event) => {
         if (isAuthenticated) {
            event.preventDefault();
            event.returnValue = '';
         }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, [isAuthenticated]);

   useEffect(() => {
      const isAuthPage = !['/login', '/signup', '/landingPage'].includes(location.pathname);
      setIsAuthenticated(isAuthPage);
   }, [location]);


   return (
      <AppContext.Provider value={{
         thumbnailData,
         setThumbnailData,
         videoUrl,
         setVideoUrl,
         landingPageUrl,
         setLandingPageUrl,
         variables,
         setVariables,
         selectedVariables,
         setSelectedVariables,
         landingPageData,
         setLandingPageData,
         transcription,
         setTranscription,
         avatarId,
         setAvatarId,
         script,
         setScript,
         currentUser,
         setCurrentUser,
         variablesWithoutDuplicates,
         setVariablesWithoutDuplicates,
         avatarTrainingStep,
         setAvatarTrainingStep,
         currentPlan,
         setIsAuthenticated,
      }}>
         {children}
      </AppContext.Provider>
   );
};

export default AppContext;
