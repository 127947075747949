import React, { useEffect, useState, useMemo, useContext } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../components/ui/popover"

import { Input } from "../../../../components/ui/input";
import { Search, Filter, Trash2, Plus, FileDown } from 'lucide-react'
import Atom from "../../atoms";
import { Button } from "../../../../components/ui/button";
import { ScrollArea, ScrollBar } from '../../../../components/ui/scroll-area';
import _ from 'lodash';
import { nanoid } from 'nanoid'

const STATUSES = [
    { id: 1, name: 'Pending', bgColor: '#fef9c3', color: '#CA8A04', },
    { id: 2, name: 'Queued', bgColor: '#DBEAFE', color: '#2563EB', },
    { id: 3, name: 'Started', bgColor: '#EFEAFF', color: '#612FFF', },
    { id: 4, name: 'Completed', bgColor: '#dcfce6', color: '#16A34A', },
]

const getStatusObject = (statusString) => {
    if (typeof statusString === 'string') {
        return STATUSES.find(s => s.name.toLowerCase() === statusString.toLowerCase());
    }
    return null;
};

const DeleteCell = ({ getValue, row, column, table }) => {
    const { updateData } = table.options.meta;

    const statusObj = getStatusObject(row.original.status);

    const handleDelete = (value) => {
        updateData({ rowIndex: row.index, columnId: column.id, value, id: row.original.id });
    };

    const isDisabled = statusObj?.name !== 'Pending';

    return (
        <button
            onClick={() => handleDelete(true)}
            className={`h-full ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
            disabled={isDisabled}
        >
            <Trash2 className="h-5 w-5 text-red-400" />
        </button>
    );
}
const EditableCell = ({ cell, getValue, row, column, table }) => {
    const statusObj = getStatusObject(row.original.status);

    // const initialValue = getValue();
    const initialValue = getValue() || '';
    const [value, setValue] = useState(initialValue);
    const onBlur = () => {
        setValue(value?.trim())
        table.options.meta?.updateData({ rowIndex: row.index, columnId: column.id, value, id: row.original.id });
    };

    const isDisabled = statusObj?.name !== 'Pending';

    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            className={`text-center h-full w-fit overflow-hidden truncate border-2 border-[#8707ff] bg-transparent focus:outline-none ${isDisabled ? 'cursor-not-allowed opacity-50 border-none' : ''}`}
            style={{ width: `${cell.column.getSize()}px` }}
            type="text"
            disabled={isDisabled}
        />
    );
};


const ColorIcon = ({ bgColor, ...props }) => (
    <div style={{ width: '12px', height: '12px', border: '1px solid #ccc', borderRadius: '3px', backgroundColor: bgColor }} {...props}></div>
);

const StatusCell = ({ getValue, row, column, table }) => {
    const statusObj = getStatusObject(getValue());
    const { bgColor, color } = statusObj || {};

    return (
        <div className='flex justify-center items-center h-full w-full'>
            <div className="font-semibold flex justify-center items-center text-sm h-full w-full"
                style={{
                    backgroundColor: bgColor || "",
                    color: color
                }}
            >
                {getValue()}
            </div>
        </div>
    )
}

const Filters = ({ columnFilters, setColumnFilters, statuses }) => {
    const filteredEmail = columnFilters.find((f) => f.id === "Email")?.value || "";

    const onFilterChange = (id, value) =>
        setColumnFilters((prev) =>
            prev
                .filter((f) => f.id !== id)
                .concat({
                    id,
                    value,
                })
        );

    return (
        <div className='flex gap-2 items-center'>
            <div className="relative w-48 group">
                <Input
                    onChange={(e) => onFilterChange("Email", e.target.value)}
                    value={filteredEmail}
                    placeholder="Email"
                    className="pr-10 w-[12rem] pl-5 rounded-3xl text-xs text-[#9B9B9B] border-gray-300 hover:border-gray-500"
                />
                <Search className="absolute text-[#9B9B9B] h-4 w-4 top-3 right-4 group-hover:text-gray-500" />
            </div>
            <FilterPopover statuses={statuses} columnFilters={columnFilters} setColumnFilters={setColumnFilters} />
        </div>
    )
}


const StatusItem = ({ status, setColumnFilters, isActive }) => {
    const statusObj = getStatusObject(status);

    return (
        <div
            className='flex gap-1 items-center cursor-pointer rounded-md px-3 py-2 hover:bg-gray-100'
            style={{ backgroundColor: isActive ? "#E4E7EB" : "" }}
            onClick={() => {
                setColumnFilters((prev) => {
                    const statuses = prev.find((filter) => filter.id === "status")?.value;
                    if (!statuses) {
                        return prev.concat({
                            id: "status",
                            value: [statusObj.id],

                        });
                    }

                    return prev.map((f) =>
                        f.id === "status"
                            ? {
                                ...f,
                                value: isActive
                                    ? statuses.filter((s) => s !== statusObj.id)
                                    : statuses.concat(statusObj.id),
                            }
                            : f
                    );
                })
            }}
        >
            <ColorIcon bgColor={statusObj?.bgColor} />
            {statusObj?.name}
        </div>
    )
}


const FilterPopover = ({ columnFilters, setColumnFilters, statuses, }) => {
    // const filterStatuses = columnFilters.find((f) => f.id === "status")?.value || [];

    const filterStatuses = useMemo(() => {
        return columnFilters.find((f) => f.id === "status")?.value || [];
    }, [columnFilters]);

    const isActive = useMemo(() => filterStatuses.length > 0, [filterStatuses]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    type="outline"
                    // className="h-9 px-3 border border-gray-300 bg-transparent hover:bg-gray-500/10 text-gray-500 group hover:text-gray-600 hover:border-gray-500 rounded-3xl grow md:grow-0 text-center text-sm font-semibold"
                    className={`h-9 px-3 border bg-transparent rounded-3xl grow md:grow-0 text-center text-sm font-semibold active:shadow-2xl active:scale-105 transition-all duration-300 group
                    ${isActive ? 'bg-violet-100 hover:bg-violet-100/90 text-violet-600 hover:text-violet-600/90 border-violet-600 hover:border-violet-600/90' : 'bg-transparent hover:bg-gray-500/10 border-gray-300 hover:border-gray-500 text-gray-500 hover:text-gray-600'}`}
                >
                    <Filter
                        // className='h-5 w-5 mr-1 group-hover:text-gray-600' 
                        className={`h-5 w-5 mr-1 ${isActive ? 'text-violet-600 hover:text-violet-600/90' : 'group-hover:text-gray-600'}`}
                    />
                    Filter
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-50">
                <div className="grid gap-4">
                    <div>
                        <h4 className="font-medium leading-none">Filter By:</h4>
                    </div>
                    <div className='space-y-2'>
                        <p className="text-sm text-muted-foreground">
                            Status
                        </p>
                        <div className='flex flex-col justify-center items-start gap-1'>
                            {
                                statuses?.map((statusString) => {
                                    const statusObj = getStatusObject(statusString);
                                    return (
                                        <StatusItem
                                            status={statusString}
                                            setColumnFilters={setColumnFilters}
                                            isActive={filterStatuses.includes(statusObj.id)}
                                            key={statusObj.id}

                                        />
                                    );
                                })
                            }
                        </div>

                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}


const AddNewRow = ({ initialObject, setLocalTableData, setTableData }) => {

    const handleAddRow = () => {
        const newRow = _.clone(initialObject);
        newRow.id = nanoid();
        setLocalTableData(prev => [...prev, newRow])
        setTableData(prev => [...prev, newRow]);
    };

    return (
        <div className='flex justify-center'>
            <Atom.Button
                type="outline"
                // className="rounded-3xl text-gray-500 text-sm hover:border-[#333] hover:text-violet-600"
                className="h-9 px-3 border-gray-300 bg-transparent hover:bg-gray-500/10 text-gray-500 group hover:text-gray-600 hover:border-gray-500"

                onClick={handleAddRow}
            >
                <Plus className='h-5 w-5 group-hover:text-gray-600' />
                Add Row
            </Atom.Button>
        </div>

    )
}

const SummaryTable = ({ csvData, tableData, setTableData, statuses, generatedData, variables }) => {

    const [localTableData, setLocalTableData] = useState([]);

    const [initialObject, setInitialObject] = useState({})

    useEffect(() => {
        const initialData = csvData.map((item, index) => ({
            ...item,
            id: nanoid(),
            status: statuses[0],
            videoUrl: "",
            thumbnail: "",
            landing_page: "",
            delete: false,
            // generated: false,
        }));

        const mergedData = generatedData.length > 0 ? [...generatedData, ...initialData] : initialData;
        setTableData([...tableData, ...mergedData]);
    }, [csvData]);

    useEffect(() => { console.log(tableData); setLocalTableData(tableData) }, [tableData])



    const fixedColumns = [
        {
            accessorKey: 'video_url',
            header: 'Video URL',
            size: 220,
            cell: (props) => (
                <a
                    href={props.getValue()}
                    className='h-full p-2 text-xs text-center items-center justify-start flex text-indigo-600 underline'
                    title={props.getValue()}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {props.getValue()}
                </a>
            ),
        },
        {
            accessorKey: 'thumbnail',
            header: 'Thumbnail',
            size: 220,
            cell: (props) => <p className='h-full p-2 text-xs text-center items-center justify-start flex text-indigo-600 underline' title={props.getValue()}>{props.getValue()}</p>,
        },
        {
            accessorKey: 'landing_page',
            header: 'Landing Page URL',
            size: 220,
            cell: (props) => <p className='h-full p-2 text-xs text-center items-center justify-start flex text-indigo-600 underline ' title={props.getValue()}>{props.getValue()}</p>,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 130,
            cell: StatusCell,
            enableColumnFilter: true,
            filterFn: (row, columnId, filterStatuses) => {
                if (filterStatuses.length === 0) return true;
                const status = row.getValue(columnId);
                const statusObj = getStatusObject(status);
                return filterStatuses.includes(statusObj?.id);
            },
        },
        {
            accessorKey: 'delete',
            header: 'Delete',
            size: 80,
            cell: DeleteCell,
        },
    ];

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const dynamicColKeys = [];

        if (variables?.length > 0) {
            const dynamicColumns = variables.map(v => {
                const key = v.variable.replace(/ /g, '_');

                dynamicColKeys.push(key)

                return {
                    accessorKey: key,
                    header: v.variable.replace(/_/g, ' '),
                    id: v.variable.replace(/ /g, '_'),
                    size: 210,
                    cell: EditableCell,
                }
            });

            dynamicColumns.unshift({
                accessorKey: "Email",
                header: "Email",
                id: "Email",
                size: 235,
                cell: EditableCell,
            });

            dynamicColKeys.unshift("Email");

            setColumns([...dynamicColumns, ...fixedColumns,])
            // columns.concat(...fixedColumns, ...dynamicColumns)

            setInitialObject({
                id: -1,
                status: statuses[0],
                videoUrl: "",
                thumbnail: "",
                landing_page: "",
                delete: false,
                // ...(_.zipObject(dynamicColKeys, ""))
                ...Object.fromEntries(dynamicColKeys.map(key => [key, ""]))
            })
        }
    }, [variables])


    const columnOrder = ['Email', 'Status', 'Video URl', 'Thumbnail', 'Landing Page URL',];

    const [columnFilters, setColumnFilters] = useState([]);


    const table = useReactTable({
        data: localTableData,
        columns,
        state: {
            columnFilters,
            columnOrder,
            // tableData,
            statuses,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        columnResizeMode: "onChange",
        meta: {
            updateData: ({ rowIndex, columnId, id, value }) => {
                if (columnId === 'delete') {
                    const rows = localTableData.filter(row => row.id !== id);
                    return setTableData(rows);
                }
                const tempRows = [...localTableData];
                if (tempRows[rowIndex][columnId]?.trim() !== value?.trim()) {
                    tempRows[rowIndex][columnId] = value;
                    return setTableData(tempRows)
                }
            }
        }

    })

    const exportDataHandler = () => {
        const filteredTableData = tableData.map(({ id, delete: del, ...rest }) => rest);

        const tableDataInCsv = Papa.unparse(filteredTableData);

        const blob = new Blob([tableDataInCsv], { type: 'text/csv;charset=utf-8' });

        saveAs(blob, 'tableData.csv');
    };


    return (
        <div className='max-w-[80vw] md:max-w-[65vw] lg:max-w-[90vw] xl:max-w-[80vw] 3xl:[w-80vw] 3xl:max-w-[85vw] rounded-3xl border border-[#ccc] relative pb-1' style={{ width: `${table.getTotalSize()}px` }}>
            <ScrollArea>
                <div className='flex gap-2 items-center p-2 rounded-t-3xl'>
                    <Filters columnFilters={columnFilters} setColumnFilters={setColumnFilters} statuses={statuses} />
                    <AddNewRow
                        initialObject={initialObject}
                        setTableData={setTableData}
                        setLocalTableData={setLocalTableData}
                    />

                    <Atom.Button
                        type="outline"
                        className="h-9 px-3 border-gray-300 bg-transparent hover:bg-gray-500/10 text-gray-500 group hover:text-gray-600 hover:border-gray-500"
                        onClick={exportDataHandler}
                    >
                        <FileDown className='h-5 w-5 mr-1 group-hover:text-gray-600' />
                        Export Data
                    </Atom.Button>


                </div>
                <div className=''>
                    <div className='border-b border-[#ccc] py-3' style={{ width: `${table.getTotalSize()}px` }}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <div className='flex w-fit' key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <div className="relative border-none flex justify-center items-center text-gray-400 py-1 font-bold uppercase text-xs text-center group" style={{ width: `${header.getSize()}px` }} key={header.id} colSpan={header.colSpan}>
                                        {header.column.columnDef.header}

                                        {/* for column resiser */}
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className="absolute opacity-0 top-0 right-0 h-full w-[5px] cursor-col-resize select-none touch-action-none rounded-3xl group-hover:opacity-100"
                                            style={{
                                                backgroundColor: header.column.getIsResizing() ? "#94F6BB" : "#612fff",
                                                // opacity: header.column.getIsResizing() ? 1 : 0
                                            }}
                                        >
                                        </div>

                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    {/* Render "No data currently" message if tableData is empty */}
                    {!table.getRowModel().rows.length && (
                        <div className='text-gray-500 text-center p-10'>No dynamic videos found!</div>
                    )}
                    {table.getRowModel().rows.map(row => {
                        if (row.original.delete) return null;

                        return (
                            <div className='flex w-fit' key={row.original.id}>
                                {row.getVisibleCells().map(cell => (
                                    <div className='border border-dotted text-center h-12 overflow-hidden' style={{ width: `${cell.column.getSize()}px` }} key={cell.id}>
                                        {cell.column.columnDef.cell ? (
                                            flexRender(cell.column.columnDef.cell, cell.getContext())
                                        ) : (
                                            <EditableCell
                                                cell={cell}
                                                getValue={cell.getValue}
                                                row={row}
                                                column={cell.column.columnDef}
                                                table={table}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
                <br />
                {table.getRowModel().rows.length > 0 && (
                    <div className='items-center justify-center flex flex-col'>
                        <div className='mb-2 text-sm text-gray-500'>
                            Page {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                        </div>
                        <div>
                            <Button
                                variant="outline"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {"<"}
                            </Button>
                            <Button
                                variant="outline"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {">"}
                            </Button>
                        </div>
                        <ScrollBar orientation="horizontal" className="absolute overflow-hidden" />
                    </div>
                )}
            </ScrollArea>
        </div>
    )
}

export default SummaryTable;

