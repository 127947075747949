import React from 'react';
import Atom from '../atoms';

const Overlay = ({ isVisible }) => {
    return isVisible ? (
        <div className="fixed inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center" style={{ zIndex: 1050 }}>
            <Atom.Loader />
        </div>
    ) : null;
};

export default Overlay;
