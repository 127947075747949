import React, { Fragment, useState, useContext } from 'react';
import { Link, matchPath, useLocation } from "react-router-dom";
import { useClerk, Protect } from "@clerk/clerk-react";

import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "../../../../lib/utils";
import AppContext from '../../../context/AppContext';

import { routes, AdminRoutes } from "./constants";

import Atom from '../../atoms';
import { MainLogo, Personiq } from "../../../assets/images";
import HelpIcon from '../../../assets/sidebar-icons/HelpIcon';
import SettingsIcon from '../../../assets/sidebar-icons/SettingsIcon';
import LogoutIcon from '../../../assets/sidebar-icons/LogoutIcon';
import { resetAuthTokens } from "../../../../apis/axios";
import { clearSessionStorageCredentials } from '../../../../lib/storage';
import Organism from '..';


const Sidebar = () => {
    const { currentUser } = useContext(AppContext);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const { signOut } = useClerk();

    const matchedPath = pathname => {
        const path = matchPath({ path: pathname, exact: true }, location.pathname);
        if (!path) {
            return location.pathname.startsWith(pathname) ? { pattern: { exact: false } } : null;
        }
        return path;
    };

    const handleHelp = () => {
        console.log('help');
    };

    const handleSettings = () => {
        console.log('settings');
    };

    const handleLogout = async () => {
        resetAuthTokens();
        clearSessionStorageCredentials();
        await signOut();
        window.location.href = "/login"
    };


    const MenuItem = ({ item, entry }) => {
        const isPathMatched = matchedPath(item.to)

        return (
            <Link
                key={item.name}
                to={item.to}
                className={cn(
                    isPathMatched
                        ? "text-[#612FFF]  bg-[#DFD5FF]"
                        : "text-gray-600 bg-[#F3F3F6] hover:bg-[#F3F3F6]/90 hover:text-gray-900",
                    "group flex items-center justify-center rounded-3xl px-5 py-[.6rem] text-lg font-semibold"
                )}
            >
                {/* <div>{item.icon(matchedPath(item.to)?.pattern?.exact)}</div> */}
                <div>{item.icon(location.pathname.startsWith(item.to))}</div>
                <span className="grow ml-3">{item.name}</span>
            </Link>
        );
    }


    const MainMenu = () => {
        return (
            <nav className="mt-8 flex-1 space-y-3 bg-[#F3F3F6] px-3">
                <Atom.Visibility state={location.pathname.startsWith('/admin')}>
                    {AdminRoutes.map((item, index) => (
                        <MenuItem item={item} key={index} />
                    ))}
                </Atom.Visibility>

                <Atom.Visibility state={!location.pathname.startsWith('/admin')}>
                    {routes.map((item, index) => (
                        <MenuItem item={item} key={index} />
                    ))}
                </Atom.Visibility>

            </nav>
        );
    }


    const BottomMenu = () => {
        return (
            <div className='pb-4'>
                <Organism.Credits showButton={true} />
            </div>
        );
    }

    return (
        <>
            <Transition.Root as={Fragment} show={sidebarOpen}>
                <Dialog
                    as="div"
                    className="relative z-40 md:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            type="button"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon
                                                aria-hidden="true"
                                                className="h-6 w-6 text-white"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                    <div className="flex gap-2 flex-shrink-0 items-center justify-center px-4 py-3">
                                        <img
                                            alt="Your Company"
                                            className="h-8 w-auto"
                                            src={MainLogo}
                                        />
                                        <img
                                            alt="Personiq"
                                            src={Personiq}
                                        />
                                    </div>
                                    <MainMenu />
                                </div>
                                <BottomMenu />
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* Static sidebar for desktop */}
            <div className="z-50 hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col bg-[#F3F3F6]">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex min-h-0 flex-1 flex-col">
                    <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                        <div className="flex gap-2 flex-shrink-0 items-center justify-center px-4 py-2 pl-0">
                            <img
                                alt="Main Logo"
                                className="h-10 w-auto"
                                src={MainLogo}
                            />
                            <img
                                alt="Personiq"
                                src={Personiq}
                            />
                        </div>
                        <MainMenu />
                    </div>
                    <BottomMenu />
                </div>
            </div>
            <div className="flex flex-1 flex-col pt-14 md:pt-0 md:pl-64">
                <div className="fixed md:hidden top-0 left-0 w-full z-10">
                    <div className="h-14 w-full border-b border-gray-200 bg-white flex items-center justify-between">
                        <div>
                            <button
                                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                type="button"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className='flex gap-2 flex-shrink-0 items-center justify-center px-4 py-2 pl-0'>
                            <img
                                alt="Your Company"
                                className="h-8 w-auto"
                                src={MainLogo}
                            />
                            <img
                                alt="Personiq"
                                src={Personiq}
                            />
                        </div>
                        <div>
                            <div className="mr-1"><Atom.Avatar name='Debanga Goswami' size="small" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Sidebar