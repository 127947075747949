import Table from "./Table";
import Header from "./Header";
import Content from "./Content";
import CustomPagination from "./CustomPagination";
import Overlay from "./Overlay";
import Modal from "./Modal";
import Logo from "./Logo";
import AvatarCard from "./AvatarCard";
import UploadButtons from "./FileUploader";
import TextAreaWithSuggestion from "./TextAreaWithSuggestion";
import Tags from "./Tags";
import VideoRecorder from "./VideoRecorder";
import Instructions from "./Instructions";
import VideoInstructions from "./VideoInstructions";
import CheckoutForm from "./CheckoutForm";
import BreadcrumbTrails from "./BreadcrumbTrails";
import Credits from "./Credits";



const Organism = {
  Table,
  Header,
  Content,
  CustomPagination,
  Overlay,
  Modal,
  Logo,
  AvatarCard,
  UploadButtons,
  TextAreaWithSuggestion,
  Tags,
  VideoRecorder,
  Instructions,
  VideoInstructions,
  CheckoutForm,
  BreadcrumbTrails,
  Credits
}

export default Organism;
