import { cn } from "../../../lib/utils";
import React from 'react';
import {
  Tooltip,
  TooltipProvider,
} from "../../../components/ui/tooltip";

import { Skeleton } from "components/ui/skeleton";

const Avatar = ({
  imageUrl,
  name = getNameFromStorage(),
  size = "medium",
  border = false,
  initials = true,
  backgroundColor = "bg-blue-600",
  textColor = "text-white",
  // tooltip = null,
  // tooltipPosition = "right",
}) => {

  const splitName = name ? name.split(" ") : null;

  if (!name) {
    return (
      <div className={cn("flex items-center space-x-3")}>
        <Skeleton className={cn({
          "h-8 w-8": size === "extra-small",
          "h-10 w-10": size === "small",
          "h-12 w-12": size === "medium",
          "h-16 w-16": size === "large",
          "h-20 w-20": size === "extra-large",
        })} />
      </div>
    );
  }

  const nameInitials = `${splitName[0][0]}${splitName[splitName.length - 1][0]}`


  return (
    <div className={cn({
      "w-8 h-8": size === "extra-small",
      "w-10 h-10": size === "small",
      "w-12 h-12": size === "medium",
      "w-16 h-16": size === "large",
      "w-20 h-20": size === "extra-large",
      "border-2 border-white": border,
    }, "flex items-center space-x-3 rounded-full overflow-hidden")}>
      {/* <TooltipProvider> */}
      {/* <Tooltip title={tooltip} placement={tooltipPosition}> */}
      {
        imageUrl
          ? <img className={cn({
            "w-8 h-8": size === "extra-small",
            "w-10 h-10": size === "small",
            "w-12 h-12": size === "medium",
            "w-16 h-16": size === "large",
            "w-20 h-20": size === "extra-large",
          }, "rounded-full")} src={imageUrl} />
          : <div className={cn({
            "flex items-center justify-center rounded-full uppercase font-semibold": true,
            "w-8 h-8 text-xs": size === "extra-small",
            "w-10 h-10 text-md": size === "small",
            "w-12 h-12 text-lg": size === "medium",
            "w-16 h-16 text-xl": size === "large",
            "w-20 h-20 text-xl": size === "extra-large",
          }, backgroundColor, textColor)}>{initials ? nameInitials : name}</div>
      }
      {/* </Tooltip> */}
      {/* </TooltipProvider> */}
    </div>
  );
}

const getNameFromStorage = () => {
  const storedName = sessionStorage.getItem("name");
  return storedName ? storedName.replace(/^"|"$/g, "") : null;
};

export default Avatar;
