import React, { useState, useEffect } from 'react';
import Organism from '../organisms';

const CampaignsTemplate = ({ children, handleOpenDialog, onSearchChange }) => {
    return (
       <div className="flex flex-col h-full gap-1">
          <Organism.Header
             title="Campaigns"
             buttonLabel="Create Campaign"
             onButtonClick={handleOpenDialog}
             inputPlaceholder="Search your campaign"
             onSearchChange={onSearchChange}
             elements={["SEARCH", "BUTTON"]}
             listPage={true}
          />
          <main className="flex flex-col h-full gap-1 overflow-hidden">
             {children}
          </main>
       </div>
    );
 };

export default CampaignsTemplate;
